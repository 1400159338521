@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .icon {
    @apply inline-block fill-current;

    width: 1em;
    height: 1em;
  }

  .button {
    @apply inline-block rounded px-6 pb-2 pt-2.5 mr-2 font-medium;
  }

  .btn-primary {
    @apply bg-blue hover:bg-blue-600 text-white;
  }

  .btn-secondary {
    @apply bg-yellow hover:bg-yellow-700 text-black;
  }

  .title {
    @apply my-1;
  }

  h1 {
    @apply text-blue text-3xl group-[.bg-blue]:text-white;
  }

  h2 {
    @apply text-blue text-2xl font-bold group-[.bg-blue]:text-white;
  }

  h3 {
    @apply text-yellow-700 text-xl font-bold group-[.bg-yellow]:text-black;
  }

  h4 {
    @apply uppercase text-base text-blue text-lg font-bold group-[.bg-blue]:text-white;
  }

  .paragraph ul {
    @apply list-disc list-inside;
  }

  .paragraph ol {
    @apply list-decimal list-inside;
  }
}

iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
}

video {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%; 
}

a[href^="mailto:"]::before {
    content: "✉";
    margin-right: .5em;
}